import { Box, useTheme } from '@mui/material';
import { DateTimeValidationError, PickerChangeHandlerContext, TimePicker } from '@mui/x-date-pickers';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { Moment } from 'moment';
import { useEffect, useMemo, useState } from 'react';

import { FlexRow } from '../../../styled/Flex';
import FormDataErrorField from '../FormDataErrorField';
import { CustomDateProps, getValue } from './CustomDateTimePicker';

const CustomTimePicker = (props: CustomDateProps) => {
  const theme = useTheme();
  const isAmPm = useMemo(() => {
    const lastLetter = props.format.slice(-1);
    if (['a', 'A'].includes(lastLetter)) {
      return true;
    }
    return false;
  }, [props.format]);

  const [errorMessage, setErrorMessage] = useState<string>();
  const [showError, setShowError] = useState<boolean>(false);

  useEffect(() => {
    const [elementId] = props.names[0].split('.');
    props.setDisableSubmit?.({ [elementId]: errorMessage ? true : false });
  }, [errorMessage, props, showError]);

  return (
    <Box>
      <FlexRow columnGap={2}>
        {props.names.map((name, index) => (
          <Box key={name}>
            <TimePicker
              ampm={isAmPm}
              viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
              }}
              desktopModeMediaQuery={theme.breakpoints.up('md')}
              value={getValue(props.elementValue, name, props.type)}
              onChange={(date: Moment | null, context: PickerChangeHandlerContext<DateTimeValidationError>) => {
                if (context.validationError == null) {
                  props.onChange(date, name);
                } else {
                  props.onChange(null, name);
                }
              }}
              onError={(error: DateTimeValidationError) => {
                if (error) {
                  setErrorMessage('Invalid time');
                } else {
                  setErrorMessage(undefined);
                }
              }}
              format={props.format}
              // minTime={getMinDate(props.elementValue, props.names[0], props.type, props.range, index)} // TODO plugin not support min/max time for undefined value
              // maxTime={getMaxDate(props.elementValue, props.names[1], props.type, props.range, index)}
              disabled={props.disabled}
              timezone={props.timezone}
              slotProps={{
                field: { clearable: true },
                textField: {
                  id: name,
                  name,
                  placeholder: props.placeholder,
                  onBlur: () => {
                    setShowError(true);
                  },
                },
              }}
            />
          </Box>
        ))}
      </FlexRow>
      <FormDataErrorField
        error={props.error?.length ? props.error : showError && errorMessage ? errorMessage : undefined}
        touched={props.touched ?? undefined}
      />
    </Box>
  );
};

export default CustomTimePicker;
