import { Stack } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Moment } from 'moment';

import { DateRange } from '../../../common/dto/formdata.dto';
import { Config } from '../../../common/dto/template.dto';
import { FormDataFieldCommonProps } from '../../../common/dto/template-builder.model';
import { DisableFormSubmit } from '../../../hoc/WithSideDrawer';
import { useAppSelector } from '../../../redux/hooks';
import { DATE_FORMAT, DATE_TIME_FORMAT, TIME_FORMAT } from '../../common/DateFormat';
import CustomDatePicker from './components/CustomDatePicker';
import CustomDateTimePicker from './components/CustomDateTimePicker';
import CustomTimePicker from './components/CustomTimePicker';

interface FieldProps extends FormDataFieldCommonProps {
  setDisableSubmit?: (filelds: DisableFormSubmit) => void;
}

export enum DateMode {
  datetime = 'datetime',
  time = 'time',
  date = 'date',
}

const getPickerType = (config: Config) => {
  if (config.date && config.time) {
    return DateMode.datetime;
  }
  if (config.date) {
    return DateMode.date;
  }
  return DateMode.time;
};

export const getDateFormat = (config: Config) => {
  if (config.date && config.time) {
    return DATE_TIME_FORMAT;
  }
  if (config.date) {
    return DATE_FORMAT;
  }
  return TIME_FORMAT;
};

const FormDataDateTimeField = (props: FieldProps) => {
  const { element } = props;
  let names: string[];
  let elementValue: string | DateRange;
  const format = getDateFormat(element.config);
  const type = getPickerType(element.config);

  const userTimezone = useAppSelector((state) => state.userInfo.timezone);

  if (element.config.range) {
    elementValue = (props.value?.value ? props.value?.value : { from: '', to: '' }) as DateRange;
    names = [`${element.elementId}.from`, `${element.elementId}.to`];
  } else {
    elementValue = (props.value?.value ? props.value?.value : '') as string;
    names = [element.elementId];
  }

  const onChange = (date: Moment | null, name: string) => {
    let value: string | DateRange;
    const [elementId, objName] = name.split('.');

    const dateValue = date?.toISOString();
    if (element.config.range) {
      value = structuredClone(elementValue) as DateRange;
      if (objName === 'from') {
        value.from = dateValue as string;
      } else {
        value.to = dateValue as string;
      }
    } else {
      value = dateValue as string;
    }
    let filedValue = props.value;
    if (filedValue) {
      filedValue.value = value;
    } else {
      filedValue = {
        value,
        issues: [],
        pictures: [],
      };
    }
    if (props.handleChange) props.handleChange(element.elementId, filedValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Stack direction="row" spacing={2}>
        {type === DateMode.datetime && (
          <CustomDateTimePicker
            names={names}
            placeholder={element.config.placeholder}
            // range={element.config.range}
            format={format}
            elementValue={elementValue}
            type={type}
            onChange={onChange}
            disabled={false}
            error={props.error}
            touched={props.touched}
            timezone={userTimezone}
            setDisableSubmit={props.setDisableSubmit}
          />
        )}
        {type === DateMode.date && (
          <CustomDatePicker
            names={names}
            placeholder={element.config.placeholder}
            // range={element.config.range}
            format={format}
            elementValue={elementValue}
            type={type}
            onChange={onChange}
            disabled={false}
            error={props.error}
            touched={props.touched}
            timezone={userTimezone}
            setDisableSubmit={props.setDisableSubmit}
          />
        )}
        {type === DateMode.time && (
          <CustomTimePicker
            names={names}
            placeholder={element.config.placeholder}
            // range={element.config.range}
            format={format}
            elementValue={elementValue}
            type={type}
            onChange={onChange}
            disabled={false}
            error={props.error}
            touched={props.touched}
            timezone={userTimezone}
            setDisableSubmit={props.setDisableSubmit}
          />
        )}
      </Stack>
    </LocalizationProvider>
  );
};

export default FormDataDateTimeField;
